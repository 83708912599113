import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { HomeFeature } from "../components/Home"
import SEO from "../components/SEO/seo"
import TestimonialIndex from "../components/testimonial"
import {
  CardContainer,
  CommonCard,
  CommonTitle,
  StyledA,
  GroupManageTitle,
  BottomLine,
} from "../components/commonFeatureComp"
import BgImgComp from "../components/bgImgComp"
import { GatsbyImage } from "gatsby-plugin-image"
import bgWeb from "../../images/bg-1.svg"
import bgMobile from "../../images/bg-2.svg"

const H1ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  position: relative;
  align-content: center;
`
const H1Container = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`

const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  margin-top: 10px;
`
const Title = styled.h2`
  font-size: 32px;
  color: #2f394e;
  text-align: center;
  padding: 0 15px;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
const TitleH1 = styled.h1`
  box-sizing: border-box;
  color: #fff;
  font-size: ${props => (props.fontSize ? props.fontSize : `42px`)};
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  padding: 20px;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 575px) {
    font-size: 23px;
  }
`
const TitleH3 = styled.h3`
  font-size: 24px;
  color: #33475b;
  text-align: start;
  margin: 2rem 0 0.5rem 0;
  @media (max-width: 1280px) {
    font-size: 22px;
  }
`
const Bottomline = styled.div`
  width: 50px;
  height: 3px;
  border-radius: 10px;
  background-color: #33cdd1;
  margin: 0 auto;
`
const TitleTextContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 1000px);
  justify-content: center;
  padding: 15px 40px;
  @media (max-width: 575px) {
    padding: 15px 20px;
  }
`
const GroupManageTitleContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(1000px, 1250px);
  padding: ${props => props.padding || `3rem 30px`};
  justify-content: center;
  @media (max-width: 1024px) {
    grid-template-columns: 92%;
  }
  @media (max-width: 800px) {
    grid-template-columns: auto;
    padding: 0 20px;
    padding-bottom: ${props => props.isRefund && `25px`};
    text-align: center;
  }
`
const TitleText = styled.p`
  font-size: ${props => props.fontSize || `18px`};
  text-align: ${props => props.textAlign || `center`};
  margin: ${props => props.margin || `1.7rem 0 1rem 0`};
  color: #3d4d69;
  line-height: 1.6;
  @media (max-width: 575px) {
    font-size: 14px;
  }
`
const FeaturesCompareContainer = styled.div`
  max-width: 1250px;
  display: grid;
  grid-template-columns: 1.7fr 1fr 1fr;
  padding: 30px;
  /* justify-items: center; */
  margin: 0 auto;
  background-color: #fff;
  :nth-of-type(even) {
    background-color: #f8fcfc;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 575px) {
    padding: ${props => (props.title ? `25px 0` : `25px 10px`)};
    margin: 0 20px;
    grid-template-columns: 1.5fr 1fr 1fr;
  }
`
const FeatureCompareElement = styled.div`
  margin: 0;
  color: #33475b;
  font-size: ${props => (props.title ? `22px` : `16px`)};
  padding: 0 15px;
  display: grid;
  align-content: center;
  justify-content: ${props => props.icon && `center`};
  font-weight: ${props => props.title && `700`};
  @media (max-width: 575px) {
    padding: ${props => (props.title ? `0` : `0 5px`)};
    font-size: ${props => (props.title ? `14px` : `13px`)};
  }
`

const BrandComponent = styled.div`
  display: grid;
  grid-row-gap: 15px;
  padding: 0 20px;
  @media (max-width: 575px) {
    padding: 0 5px;
  }
`
const FreeTrialCapsule = styled.div`
  color: white;
  font-size: 14px;
  background: ${props => (props.freeTrial ? `#4DBB9D` : `#F95663`)};
  border-radius: 17px;
  padding: 8px 20px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  text-align: center;
  @media (max-width: 575px) {
    display: none;
  }
`

const FreeTrialButton = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  background-color: #fff;
  padding: 20px 35px;
  border-radius: 6px;
  border: 1px solid #33cbcf;
  font-size: 18px;
  margin: 5vh auto;
  &:hover {
    color: #fff;
    background-color: #33cbcf;
  }
  @media (max-width: 1280px) {
    font-size: 16px;
  }
`
const PlanCardContainer = styled.div`
  display: grid;
  grid-template-columns: 520px 520px;
  grid-column-gap: 40px;
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 400px;
    grid-row-gap: 20px;
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`
const PlanCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid #c2cce1;
  border-radius: 30px;
  grid-row-gap: 35px;
  padding: 35px 0;
  background-color: #fff;
`
const PlanCardTableGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px 25px;
  padding: 0 35px;
`
const PlanCardText = styled.p`
  margin: 0;
  font-size: ${props => (props.title ? `36px` : `16px`)};
  font-weight: ${props => props.title && `700`};
  padding: ${props => props.title && `0 35px`};
`
const TablePaddedContainer = styled.div`
  background-color: #eafafa;
  padding-top: 30px;
`
const ColoredContainer = styled.div`
  display: grid;
  padding: ${props => (props.isProsCons ? `45px 50px` : `30px 40px`)};
  margin: 30px 0;
  border-radius: 10px;
  background-color: ${props => props.bgColor || `#eafafa`};
  @media (max-width: 575px) {
    padding: ${props => (props.isProsCons ? `25px` : `10px 25px`)};
  }
`
const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  padding: 30px 0 1.8rem;
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`
const WebView = styled.div`
  @media (max-width: 575px) {
    display: none;
  }
`
const MobileView = styled.div`
  display: none;
  @media (max-width: 575px) {
    display: block;
  }
`

const FeatureCompareRow = props => (
  <FeaturesCompareContainer>
    <FeatureCompareElement>{props.featureText}</FeatureCompareElement>
    <FeatureCompareElement icon>
      {props.element.groupboss}
    </FeatureCompareElement>
    <FeatureCompareElement icon>{props.element.groupX}</FeatureCompareElement>
  </FeaturesCompareContainer>
)

const ColorButton = styled.div`
  padding: 16px 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #33cdd1;
  border: 1px solid #33cdd1;
  white-space: nowrap;
  border-radius: 6px;
  font-size: 18px;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #33cdd1;
    background-color: #fff;
  }
`
const ResponsiveImageWeb = styled.img`
  @media (max-width: 575px) {
    display: none;
  }
`
const ResponsiveImageMobile = styled.img`
  display: none;
  @media (max-width: 575px) {
    display: block;
  }
`
const CustomList = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-gap: 25px 15px;
  padding: 25px 0;
  align-items: center;
`
const ColoredDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${props => props.bgColor || `#92A2FA`};
`
const ListText = styled.p`
  font-size: 18px;
  font-weight: 400;
  text-align: start;
  margin: 0;
  color: #33475b;
`

export default ({ data, location }) => (
  <>
    <SEO
      title="Groupleads Alternative & Review in 2024"
      description="If you are looking for Groupleads alternative, you are in the right place. Get a comprehensive idea on Groupboss, a good alternative to Groupleads."
      pathname={location.pathname}
    />

    <H1ImageContainer>
      <ResponsiveImageWeb src={bgWeb} alt="home-background" width="100%" />
      <ResponsiveImageMobile
        src={bgMobile}
        alt="home-background mobile"
        width="100%"
      />
      <H1Container>
        <TitleH1>The Best Groupleads Alternative & Review in 2024</TitleH1>
      </H1Container>
    </H1ImageContainer>

    <TitleTextContainer>
      <TitleText>
        When it comes to Facebook group lead generation software, you have
        probably heard about Groupleads. So far, Groupleads has got many users
        who are using it to collect leads from the Facebook group.
      </TitleText>

      <TitleText>
        Groupleads has got many features though there is a question whether all
        the features work fine or not. Still, if you look at the pricing there
        are many alternatives to Groupleads with almost similar features. In
        some cases better than this tool. To learn more about Groupleads with
        the pros and cons, please check the review summary at the end of this
        page.
      </TitleText>
      <TitleText>
        If you are looking for the best Groupleads alternative, you can check
        out Groupboss.
      </TitleText>
    </TitleTextContainer>

    <div
      style={{ display: `grid`, justifyContent: `center`, margin: `20px 0` }}
    >
      <Link
        to="/pricing"
        target="_blank"
        style={{ textDecoration: "none", color: "#33475B", margin: `0 auto` }}
      >
        <ColorButton>Sign Up at Groupboss</ColorButton>
      </Link>
    </div>

    <TitleTextContainer>
      <CommonTitle
        fontSize="32px"
        fontWeight="700"
        margin="4.5rem 0"
        color="#33475B"
        style={{ textAlign: `center` }}
      >
        So, without further delay, let’s have a quick look at the comparison
        features between Groupboss and Groupleads.
      </CommonTitle>
    </TitleTextContainer>

    <TablePaddedContainer>
      <FeaturesCompareContainer title style={{ background: `#eafafa` }}>
        <FeatureCompareElement title>
          Features and Service{" "}
        </FeatureCompareElement>
        <BrandComponent>
          <WebView>
            <GatsbyImage
              image={data.gb_logo.childImageSharp.gatsbyImageData}
              alt="groupboss"
            />
          </WebView>
          <MobileView>
            <GatsbyImage
              image={data.gb_logo_mobile.childImageSharp.gatsbyImageData}
              alt="groupboss logo mobile"
              style={{ width: `50px`, margin: `0 auto` }}
            />
          </MobileView>
        </BrandComponent>
        <BrandComponent>
          <WebView>
            <GatsbyImage
              image={data.gl_logo.childImageSharp.gatsbyImageData}
              alt="groupleads logo"
            />
          </WebView>
          <MobileView>
            <GatsbyImage
              image={data.gl_logo_mobile.childImageSharp.gatsbyImageData}
              alt="groupleads logo mobile"
              style={{ width: `50px`, margin: `0 auto` }}
            />
          </MobileView>
        </BrandComponent>
      </FeaturesCompareContainer>

      <FeatureCompareRow
        featureText="Onboarding and set up"
        element={{
          groupboss: "Very easy",
          groupX: "Complex set up process",
        }}
      />
      <FeatureCompareRow
        featureText="Google sheets Integration"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Dashboard"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Integration with Autoresponders"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Zapier Required"
        element={{
          groupboss: "Optional",
          groupX: "Optional",
        }}
      />
      <FeatureCompareRow
        featureText="Custom & Lookalike Audience"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Download all data from dashboard in CSV format"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Support in Email"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Chat Support"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />
      
      <FeatureCompareRow
        featureText="Refund Policy"
        element={{ groupboss: "14 Days", groupX: "7 Days" }}
      />
      <FeatureCompareRow
        featureText="Separate Email column"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Exclusive support through zoom/skype/Gmeet"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Free Set Up Call"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Free trial"
        element={{
          groupboss: "Available ",
          groupX: "Available",
        }}
      />
      <FeatureCompareRow
        featureText="Tutorial for all features"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />
      <div style={{ display: `grid`, justifyContent: `center` }}>
        <Link
          to="/pricing"
          target="_blank"
          style={{ textDecoration: "none", color: "#33475B" }}
        >
          <FreeTrialButton>Get 7 days Free Trial</FreeTrialButton>
        </Link>
      </div>
    </TablePaddedContainer>

    <GroupManageTitleContainer>
      <GroupManageTitle>What is Groupleads?</GroupManageTitle>
      <BottomLine />

      <TitleText textAlign="start">
        It is a Facebook group automation software that helps the users to save
        answers to the facebook group questions. Facebook group admins and
        moderators can use this tool to manage the Facebook group and generate
        leads.
      </TitleText>

      <GroupManageTitle>
        Why do people look for Groupleads alternatives?
      </GroupManageTitle>
      <BottomLine />

      <TitleText textAlign="start">
        There are multiple reasons why people look for Groupleads alternatives.
        Some of them are:
      </TitleText>

      <TitleH3>Poor Customer Support</TitleH3>
      <TitleText textAlign="start">
        Customer satisfaction should be the main concern of any business.
        Groupleads is the market leader in this field. But, it seems they do not
        perform or manage their users like a market leader.
      </TitleText>
      <ImageContainer>
        <WebView>
          <GatsbyImage
            image={data.poor_customer.childImageSharp.gatsbyImageData}
            alt="poor customer support of groupleads"
          />
        </WebView>
        <MobileView>
          <GatsbyImage
            image={data.poor_customer_mobile.childImageSharp.gatsbyImageData}
            alt="poor customer support of groupleads"
          />
        </MobileView>
        <TitleText fontSize="16px">
          Source:{" "}
          <span>
            {" "}
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              rel="nofollow noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/group-leads/fflgpaejocnobejfanekjilflogejjch"
            >
              Groupleads Chrome Extension
            </StyledA>
          </span>{" "}
        </TitleText>
      </ImageContainer>

      <TitleH3>Very Expensive</TitleH3>
      <TitleText textAlign="start">
        Groupleads has many features. But their pricing is higher compared to
        its competitors. Money is a concern for everyone. Why will people invest
        too much in a software when there are similar tools available in the
        marketplace with lower pricing?
      </TitleText>

      <GroupManageTitle>Let’s look at Groupleads pricing</GroupManageTitle>
      <BottomLine />
      <ColoredContainer>
        <TitleText textAlign="start">
          1. Starter plan- <b>$27/Month</b> and <b>$97/Year</b> for only 2
          groups{" "}
        </TitleText>
        <TitleText textAlign="start">
          2. Pro plan- <b>$37/Month</b> and <b>$127/Year</b> for 4 groups{" "}
        </TitleText>
        <TitleText textAlign="start">
          3. Unlimited plan- <b>$57/Month</b> and <b>$247/Year</b>.
        </TitleText>
      </ColoredContainer>

      <GroupManageTitle>
        Why Should You Choose Groupboss over Groupleads?
      </GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        Groupboss helps you to collect leads and save the answers to the{" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          rel="dofollow"
          href="https://groupboss.io/blog/facebook-group-membership-questions/"
        >
          Facebook group questions
        </StyledA>{" "}
        in Google sheet, dashboard, and email autoresponders with a single click
        only. Groupboss is more advanced and updated regularly.
      </TitleText>
      <TitleText textAlign="start">
        On the other hand, having almost similar features Groupleads is charging
        much higher. Why should you pay that extra money if you can get the same
        important feature with comparatively lower pricing?
      </TitleText>
    </GroupManageTitleContainer>

    <div style={{ backgroundColor: `#FDFAF3`, paddingBottom: `2rem` }}>
      <GroupManageTitleContainer>
        <TitleH3>1. Pricing</TitleH3>
        <TitleText textAlign="start">
          Let’s see the difference between the pricing plans to give you a clear
          understanding?
        </TitleText>
      </GroupManageTitleContainer>

      <PlanCardContainer>
        <PlanCard>
          <div style={{ maxWidth: `210px`, paddingLeft: `35px` }}>
            <GatsbyImage
              image={data.gb_logo.childImageSharp.gatsbyImageData}
              alt="groupboss"
            />
          </div>

          <PlanCardText title>
            $45
            <span style={{ fontSize: `22px`, fontWeight: `400` }}>
              {" "}
              / quarter
            </span>
          </PlanCardText>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Quarterly</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>3</PlanCardText>
          </PlanCardTableGrid>
          {/* new added end */}
          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $99
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Yearly</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>3</PlanCardText>
          </PlanCardTableGrid>

          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $189
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Yearly (Mega Plan)</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>20</PlanCardText>
          </PlanCardTableGrid>
        </PlanCard>

        <PlanCard>
          <div style={{ maxWidth: `210px`, paddingLeft: `35px` }}>
            <GatsbyImage
              image={data.gl_logo.childImageSharp.gatsbyImageData}
              alt="gl_logo"
            />
          </div>
          <PlanCardText title>
            $27
            <span style={{ fontSize: `22px`, fontWeight: `400` }}>
              {" "}
              / month,{" "}
            </span>
            $97
            <span style={{ fontSize: `22px`, fontWeight: `400` }}> / year</span>
          </PlanCardText>

          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Starter</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>2</PlanCardText>
          </PlanCardTableGrid>

          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $37
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / month,{" "}
              </span>
              $127
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Pro</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>4</PlanCardText>
          </PlanCardTableGrid>

          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $57
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / month,{" "}
              </span>
              $247
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Unlimited</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>Unlimited</PlanCardText>
          </PlanCardTableGrid>
        </PlanCard>
      </PlanCardContainer>
      <GroupManageTitleContainer padding="0 30px 3rem 30px">

      </GroupManageTitleContainer>
    </div>

    <GroupManageTitleContainer style={{ backgroundColor: `#F4F8FF` }}>
      <TitleH3>2. Better User Experience</TitleH3>
      <TitleText textAlign="start">
        Groupboss team believes that customer satisfaction should be the top
        priority and helps the users all the way possible. Customers’ feedback
        and queries are solved with the utmost care by the Groupboss team. Here
        is what Groupboss customers are saying:
      </TitleText>

      <ImageContainer>
        <WebView>
          <GatsbyImage
            image={data.better_ux.childImageSharp.gatsbyImageData}
            alt="feedback better user experience"
          />
        </WebView>
        <MobileView>
          <GatsbyImage
            image={data.better_ux_mobile.childImageSharp.gatsbyImageData}
            alt="feedback better user experience"
          />
        </MobileView>
        <TitleText fontSize="16px">
          Source:{" "}
          <span>
            {" "}
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
            >
              Groupboss Chrome Extension
            </StyledA>
          </span>{" "}
        </TitleText>
      </ImageContainer>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#FFF5F7` }}>
      <TitleH3>3. Detailed Tutorials</TitleH3>
      <TitleText textAlign="start">
        Groupboss has the tutorials onboarding process, integrations tutorials,
        product updates, etc. both in written and video format. You will get all
        the documentation and tutorials on their{" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          href="https://groupboss.io/help/"
        >
          support page
        </StyledA>{" "}
        and{" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          href="https://www.youtube.com/c/Groupboss"
        >
          YouTube
        </StyledA>{" "}
        channel. These resources are helpful for the users and they can access
        those to learn anything about Groupboss.
      </TitleText>
      <ImageContainer>
        <WebView>
          <GatsbyImage
            image={data.detailed_tutorials.childImageSharp.gatsbyImageData}
            alt="feedback detail tutorials"
          />
        </WebView>
        <MobileView>
          <GatsbyImage
            image={
              data.detailed_tutorials_mobile.childImageSharp.gatsbyImageData
            }
            alt="feedback detail tutorials"
          />
        </MobileView>
        <TitleText fontSize="16px">
          Source:{" "}
          <span>
            {" "}
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
            >
              Groupboss Chrome Extension
            </StyledA>
          </span>{" "}
        </TitleText>
      </ImageContainer>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#EAFAFA` }}>
      <TitleH3>4. Clean and Clear UI/UX</TitleH3>
      <TitleText textAlign="start">
        Appearance of anything matters to customers once they purchase anything
        to use for a longer time. Keeping this in mind, the Groupboss team has
        created a clean and clear UI/UX so that customers get a soothing feeling
        once they use this tool.
      </TitleText>
      <ImageContainer>
        <GatsbyImage
          image={data.groupboss_poster.childImageSharp.gatsbyImageData}
          alt="groupboss poster"
        />
      </ImageContainer>
      <TitleText textAlign="start">
        Check the below message to see what Groupboss users are telling about
        its UI.
      </TitleText>
      <ImageContainer>
        <WebView>
          <GatsbyImage
            image={data.clear_ux.childImageSharp.gatsbyImageData}
            alt="feedback clean clear UX"
          />
        </WebView>
        <MobileView>
          <GatsbyImage
            image={data.clear_ux_mobile.childImageSharp.gatsbyImageData}
            alt="feedback clean clear UX"
          />
        </MobileView>
        <TitleText fontSize="16px">
          Source:{" "}
          <span>
            {" "}
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
            >
              Groupboss Chrome Extension
            </StyledA>
          </span>{" "}
        </TitleText>
      </ImageContainer>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer
      style={{ backgroundColor: `#F4F6FF` }}
      padding="3rem 30px 4rem"
      isRefund
    >
      <TitleH3>5. Refund Policy</TitleH3>
      <TitleText textAlign="start">
        Groupleads maintain a 7 days refund policy and there are instances where
        they didn’t refund their user. Groupboss maintains a straight 14 days
        refund policy and you get the scope to use Groupboss for a longer time.
      </TitleText>
      <TitleText textAlign="start">
        So far, you have come to know many things about Groupleads including its
        features and pricing. You will have to invest much of your time to learn
        about this tool and make it functional for you. In this section of the
        article, you will get a summary of this tool Facebook group automation
        tool with its pros and cons.
      </TitleText>
    </GroupManageTitleContainer>
    <GroupManageTitleContainer>
      <GroupManageTitle>Groupleads Review Summary</GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        So far, you have come to know many things about Groupleads including its
        features and pricing. You will have to invest much of your time to learn
        about this tool and make it functional for you. In this section of the
        article, you will get a summary of this tool Facebook group automation
        tool with its pros and cons.
      </TitleText>

      <ColoredContainer isProsCons bgColor="#F8FCFC">
        <TitleH3 style={{ margin: `0 0 1rem` }}>Pros</TitleH3>
        <CustomList>
          <ColoredDot bgColor="#5BEAC3" />
          <ListText>More number of features</ListText>
          <ColoredDot />
          <ListText>
            Direct integrations with email marketing autoresponders
          </ListText>
          <ColoredDot bgColor="#33CBCF" />
          <ListText>
            Automation of lead collection from Facebook groups
          </ListText>
          <ColoredDot bgColor="#FD99B4" />
          <ListText>Google sheet integration</ListText>
        </CustomList>

        <TitleH3 style={{ margin: `1.5rem 0 1rem` }}>Cons</TitleH3>
        <CustomList>
          <ColoredDot bgColor="#5BEAC3" />
          <ListText>Very expensive</ListText>
          <ColoredDot />
          <ListText>Access to only 2 groups in their starter plan</ListText>
          <ColoredDot bgColor="#33CBCF" />
          <ListText>Questionable customers support</ListText>
          
        
          <ColoredDot bgColor="#EBCD89" />
          <ListText>Quite difficult to handle and get started</ListText>
          <ColoredDot bgColor="#93BBFA" />
          <ListText>No chat support</ListText>
          
         
        </CustomList>
      </ColoredContainer>

      <GroupManageTitle>Verdict</GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        So, you have come to know the differences between Groupboss and
        Groupleads. Money matters in every field, right?
      </TitleText>

      <TitleText textAlign="start">
        Why should you pay Groupleads too much once you have got the better
        alternative to Groupleads with a lower price?
      </TitleText>

      <TitleText textAlign="start">
        It’s your turn to decide which one to use.
      </TitleText>
    </GroupManageTitleContainer>
    <TestimonialIndex alternative="groupleads" />
    <CardContainer>
      <CommonCard
        cardTextWeight="500"
        cardText="24/7 Support by real people"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="100% Secure payment"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="Best customer experience"
        cardPadding="13px 25px"
      />
    </CardContainer>
    <BgImgComp />
  </>
)

export const query = graphql`
  {
    background: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    artwork_alternative: file(
      relativePath: { eq: "groupboss-groupx-artwork.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gb_logo: file(relativePath: { eq: "gb-logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gl_logo: file(relativePath: { eq: "gl-logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gb_logo_mobile: file(relativePath: { eq: "gb-logo-2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gl_logo_mobile: file(relativePath: { eq: "gl-logo-2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    poor_customer: file(
      relativePath: { eq: "feedback-poor-customer-support.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    better_ux: file(
      relativePath: { eq: "feedback-better-user-experience.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    clear_ux: file(relativePath: { eq: "feedback-clean-clear-UX.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    groupboss_poster: file(relativePath: { eq: "shot-1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    detailed_tutorials: file(
      relativePath: { eq: "feedback-detailed-tutorials.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }

    poor_customer_mobile: file(
      relativePath: { eq: "feedback-poor-customer-support-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    better_ux_mobile: file(
      relativePath: { eq: "feedback-better-user-experience-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    clear_ux_mobile: file(
      relativePath: { eq: "feedback-clean-clear-UX-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    detailed_tutorials_mobile: file(
      relativePath: { eq: "feedback-detailed-tutorials-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }

    tick: file(relativePath: { eq: "tick_2.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 27
          placeholder: NONE
          layout: FIXED
        )
      }
    }
    cross: file(relativePath: { eq: "cross.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 27
          placeholder: NONE
          layout: FIXED
        )
      }
    }
  }
`
